import React from 'react'
import {Card} from "react-bootstrap";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import styled from "styled-components";
import LoadingBar from "../../../common/LoadingBar";
import BooksitoutImages from "../../../images/BooksitoutImages";

const SearchBookCardLoading = () => {
    return (
        <Card>
            <CardBodyContentContainer height={150}>
                <Container>
                    <CoverContainer>
                        <Cover src={BooksitoutImages.Placeholder.bookCoverLoading} alt={'placeholder'}/>
                    </CoverContainer>

                    <RowSpacer size={10}/>

                    <InfoContainer>
                        <Title><LoadingBar size={5} /></Title>
                        <Authors><LoadingBar size={8} /></Authors>
                    </InfoContainer>
                </Container>
            </CardBodyContentContainer>
        </Card>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    padding-left: 20px;
    padding-right: 20px;
`;

const CoverContainer = styled.div`
`;

const Cover = styled.img.attrs({
    className: 'img-fluid rounded border'
})`
    height: 200px;
`;

const InfoContainer = styled.div`
    text-align: center;
`;

const Title = styled.h1.attrs({
    className: 'clamp-1-line'
})`
    font-size: 20px;
`;

const Authors = styled.div.attrs({
    className: 'text-secondary clamp-1-line'
})`
`;

export default SearchBookCardLoading
