import React from 'react'
import styled from 'styled-components';
import ColorConfig from "../../../../../config/ColorConfig";

interface Props {
    title: string
    subTitle?: string

    body: () => JSX.Element
}

const BookSourceSection: React.FC<Props> = ({title, subTitle, body}) => {
    return (
        // @ts-ignore
        <Container>
            <TitleContainer>
                <Title>{title}</Title>

                {
                    subTitle &&
                    <SubTitle>{subTitle}</SubTitle>
                }
            </TitleContainer>

            {body}
        </Container>
    )
}

const Container = styled.div`
    padding: 20px;
    border-left: 5px solid ${ColorConfig.Primary};
    min-height: 200px;
`

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;


const Title = styled.h3`
    padding-bottom: 10px;
`

const SubTitle = styled.div.attrs({
    className: 'text-secondary'
})``;

export default BookSourceSection