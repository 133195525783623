import React from 'react'
import styled from "styled-components";
import BookResponse from "./BookResponse";
import {Button as BootstrapButton} from 'react-bootstrap'
import {BooksitoutServer} from '../../config/BooksitoutServer';
import toast from "react-hot-toast";
import useReadingSessionStore from "./reading/useReadingSessionStore";
import useBookDetailStore from "./detail/useBookDetailStore";

interface Props {
    book: BookResponse
}

const BookDoneButton: React.FC<Props> = ({book}) => {
    const {updateBookStatus} = useBookDetailStore()
    const {playConfetti} = useReadingSessionStore()

    const onClick = (e) => {
        e.preventDefault()

        const isDone = book.status == 'DONE'

        const confirmationMessage = !isDone ? '책을 다 읽음 처리할까요?' : '책을 읽고 있음으로 바꿀까요?'
        if (!window.confirm(confirmationMessage)) {
            return
        }

        if (!isDone) {
            BooksitoutServer
                .put(`/v1/book/${book.id}`, {status: 'DONE'})
                .then(() => {
                    playConfetti()
                    updateBookStatus('DONE')
                    toast.success('책을 다 읽었어요! 축하 드려요! 👏')
                })
                .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`))
        } else {
            BooksitoutServer
                .put(`/v1/book/${book.id}`, {status: 'READING'})
                .then(() => {
                    updateBookStatus('READING')
                    toast.success('책을 "다시 읽고 있음"으로 바꿨어요.')
                })
                .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`))
        }
    }

    return (
        // @ts-ignore
        <Button onClick={onClick} isGiveUp={book.isGiveUp}>
            {book.status == 'DONE' ? '읽는 중으로' : '다 읽음으로'}
        </Button>
    )
}

const Button = styled(BootstrapButton).attrs({
    variant: 'book-danger'
})`
    margin-top: 10px;
    width: 100%;
`;


export default BookDoneButton
