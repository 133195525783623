import React, {useState} from "react";
import styled from 'styled-components';
import {Button, Card} from 'react-bootstrap'
import CardBodyContainer from '../../../common/styles/CardBodyContainer'
import CardTitle from '../../../common/styles/CardTitle'
import booksitoutIcon from '../../../config/BooksitoutIcon'
import useLibraryNear from '../../library/useLibraryNear'
import useCurrentLocation from '../../library/useCurrentLocation';
import NoContent from '../../../common/NoContent';
import ReloadButton from '../../../common/styles/ReloadButton';
import LibraryCard from '../../library/find/LibraryCard';
import LibraryCardLoading from '../../library/find/LibraryCardLoading';
import LoadingBar from '../../../common/LoadingBar';
import RowSpacer from "../../../common/styles/RowSpacer";
import ColorConfig from "../../../config/ColorConfig";

const IndexLibraryCard = () => {
    const [isLocationActive, setIsLocationActive] = useState<boolean>(localStorage.getItem('location') !== null)

    const [lat, long, isLocationLoading, locationName, locationError, refreshLocation] = useCurrentLocation(isLocationActive)
    const [libraries, isLibraryLoading] = useLibraryNear(lat, long)

    return (
        <Card>
            <CardBodyContainer>
                <CardTitle
                    icon={<booksitoutIcon.library/>}
                    title={'내 근처 도서관'}
                    subTitle={
                        isLocationLoading ?
                            <LoadingBar size={3}/>
                            :
                            locationName
                    }
                    url='/library/near'
                />

                <ReloadButton onClick={refreshLocation}/>

                <Row>
                    {
                        !isLocationActive ?
                            <LocationRequestCard requestLocation={() => setIsLocationActive(true)}/>
                            :
                            isLocationLoading || isLibraryLoading ?
                                Array.from({length: 8}).map(_ =>
                                    <Col>
                                        <LibraryCardLoading/>
                                    </Col>
                                )
                                :
                                libraries.length === 0 ?
                                    <NoContent message={'근처에 도서관이 없어요'}/>
                                    :
                                    locationError ?
                                        <NoContent message={'위치 정보를 허용해 주세요'}/>
                                        :
                                        libraries.slice(0, 8).map(library =>
                                            <Col>
                                                <LibraryCard library={library}/>
                                            </Col>
                                        )
                    }
                </Row>
            </CardBodyContainer>
        </Card>
    )
}

interface LocationRequestCardProps {
    requestLocation: () => void
}

const LocationRequestCard: React.FC<LocationRequestCardProps> = ({requestLocation}) => {
    return (
        <LocationContainer>
            <RowSpacer size={100}/>
            <Button onClick={requestLocation} variant={'book'}>현재 위치 허용하고 근처 도서관 보기</Button>
            <RowSpacer size={10}/>
            <LocationDisclaimer>위치 정보는 성능을 위해 컴퓨터에만 임시로 저장되고 서버에는 저장되지 않아요.<br/>자세한건 <LocationDisclaimerLink
                href={"https://booksitout.com/privacy/2023-07-01?language=KOREAN"}>개인정보처리방침</LocationDisclaimerLink>을
                참고해 주세요.</LocationDisclaimer>
        </LocationContainer>
    )
}

const LocationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const LocationDisclaimer = styled.p`
    font-size: 0.8rem;
    color: #6c757d;
    text-align: center;
`

const LocationDisclaimerLink = styled.a`
    color: ${ColorConfig.Primary};
    font-weight: bold;
`

const Row = styled.div.attrs({
    className: 'row'
})`
`;

const Col = styled.div.attrs({
    className: 'col-12 col-md-6'
})`
`;

export default IndexLibraryCard