import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import IndexContentContainer from "../../index/IndexContentContainer"
import BookPageBar from '../../../common/BookPageBar';
import RowSpacer from '../../../common/styles/RowSpacer';
import BookReadingSessionButton from "../reading/BookReadingSessionButton";
import AddDeleteButton from "../../../common/button/AddDeleteButton";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import useBookDetailStore from "./useBookDetailStore";
import EditButton from "../../../common/button/EditButton";
import {useParams} from "react-router";
import BookGiveUpButton from "../BookGiveUpButton";
import BookRatingModal from "../BookRatingModal";
import Center from "../../../common/Center";
import BookRating from "../../../common/BookRating";
import BookDoneButton from "../BookDoneButton";
import {Row} from "react-bootstrap";
import breakpoints from "../../../config/Breakpoints";

const BookDetailInfoCard = () => {
    const navigate = useNavigate()

    const {bookId} = useParams()
    const {book} = useBookDetailStore()

    const [rating, setRating] = useState<number>(0)
    useEffect(() => {
        if (book != undefined) {
            setRating(book?.rating ?? 0)
        }
    }, [book]);

    const [isRatingModalOpen, setIsRatingModalOpen] = useState<boolean>(false)
    const openRatingModal = (e) => {
        e.preventDefault()
        setIsRatingModalOpen(true)
    }

    const onDelete = (e) => {
        e.preventDefault()

        if (!window.confirm('책을 삭제할까요?')) {
            return
        }

        BooksitoutServer
            .delete(`/v1/book/${book!.id}`)
            .then(() => {
                toast.success('책이 삭제됐어요')
                navigate(`/book/mine?range=READING`)
            })
            .catch((e) => toast.error(`책을 삭제할 수 없었어요. 잠시 후 다시 시도해 주세요 (${e})`))
    }

    if (book == null) {
        return <></>
    }

    return (
        <IndexContentContainer isNotHover={true}>
            <BookRatingModal isModalOpen={isRatingModalOpen} onClose={() => setIsRatingModalOpen(false)}
                             updateRating={(rating) => setRating(rating)} book={book}/>

            <DeleteButtonContainer>
                <span className={'pe-1'}>
                    <EditButton url={`/book/mine/${bookId}/edit`}/>
                </span>

                <AddDeleteButton state={'DELETE'} onDelete={onDelete}/>
            </DeleteButtonContainer>

            <Container href={`${book.isbn == null ? `` : `/book/${book.isbn}?q=${book.title}`}`}>
                <CoverContainer>
                    <Cover src={book.cover} alt={book.title}/>
                </CoverContainer>

                <InfoContainer>
                    <div className="d-block d-md-none">
                        <RowSpacer/>
                    </div>

                    <Title>{book.title}</Title>
                    <Author>{book.author}</Author>

                    <RowSpacer/>

                    <BookPageContainer>
                        <BookPageBar book={book}/>
                    </BookPageContainer>

                    <RowSpacer/>
                    {
                        book.status == 'GIVE_UP' ?
                            <Center>
                                <BookGiveUpButton book={book}/>
                            </Center>
                            :
                            book.status != 'DONE' ?
                                <ButtonContainerContainer>
                                    <ButtonContainer>
                                        <Row>
                                            <div className={'col-12 col-xl-6 order-xl-3'}>
                                                <BookReadingSessionButton book={book}/>
                                            </div>

                                            <div className={'col-6 col-xl-3 order-xl-1'}>
                                                <BookGiveUpButton book={book}/>
                                            </div>

                                            <div className={'col-6 col-xl-3 order-xl-2'}>
                                                <BookDoneButton book={book}/>
                                            </div>
                                        </Row>
                                    </ButtonContainer>
                                </ButtonContainerContainer>
                                :
                                <div onClick={openRatingModal}>
                                <BookRating rating={rating} size={30}/>
                                </div>
                    }
                </InfoContainer>
            </Container>
        </IndexContentContainer>
    )
}

const Container = styled.a.attrs({
    className: 'row'
})`
    width: 100%;
    align-items: center;
    padding: 0;
    margin: 0;
    z-index: 0;

    &:hover {
        color: inherit;
    }
`;

const CoverContainer = styled.div.attrs({
    className: 'col-12 col-md-4'
})`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Cover = styled.img.attrs<{ src: any }>({
    className: 'rounded border'
})`
    max-height: 250px;
`;

const InfoContainer = styled.div.attrs({
    className: 'col-12 col-md-8'
})`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const Title = styled.h1`
    font-size: 1.2rem;
`;

const Author = styled.h2.attrs({
    className: 'text-secondary'
})`
    font-size: 1rem;
`;

const BookPageContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const DeleteButtonContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;

    z-index: 10;
`

const ButtonContainerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const ButtonContainer = styled.div`
    max-width: 500px;
    width: 100%;

    @media (max-width: ${breakpoints.md}) {
        max-width: 500px;
    }
    
    @media (max-width: ${breakpoints.xl}) {
        max-width: 400px;
    }
`;

export default BookDetailInfoCard
