import React from 'react'
import styled from 'styled-components';
import RouteContainer from "../../common/styles/RouteContainer";
import RowSpacer from "../../common/styles/RowSpacer";
import FaqData from "./FaqData";
import ContentContainer from "../../common/styles/ContentContainer";
import parse from 'html-react-parser'
import useHtmlTitle from "../../common/useHtmlTitle";

const FaqRoute = () => {
    useHtmlTitle('자주 묻는 질문')

    return (
        <RouteContainer>
            <RowSpacer/>
            <RouteTitle>자주 묻는 질문</RouteTitle>
            <RouteSubTitle>자주 묻는 질문을 모았어요. 더 궁금한게 있으시면 언제든 support@booksitout.com으로 알려 주세요!</RouteSubTitle>

            <RowSpacer/>
            {
                FaqData.map((FaqDatum) => (
                    <>
                        <FaqContainer key={FaqDatum.id}>
                            <FaqQuestion>{FaqDatum.question}</FaqQuestion>
                            <hr/>
                            <FaqAnswer>{parse(FaqDatum.answer)}</FaqAnswer>
                        </FaqContainer>
                        <RowSpacer/>
                    </>
                ))
            }
        </RouteContainer>
    )
}

const RouteTitle = styled.h1`
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
`

const RouteSubTitle = styled.h5.attrs({
    className: 'text-secondary',
})`
    text-align: center;
`

const FaqContainer = styled(ContentContainer)`
    display: flex;
    flex-direction: column;
    
    &:hover {
        color: black;
    }
`

const FaqQuestion = styled.h3`
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const FaqAnswer = styled.p`
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
`;

export default FaqRoute
